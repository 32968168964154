import axios from '@/common/js/request'

export function equipmentListCov(params){
    const equipmentListCov = axios({
        url: 'patrol/picture/layers/equipment/list',
        method: 'get',
        params
    })
    return equipmentListCov
}


export function LineListCov(params){
    const LineListCov = axios({
        url: 'patrol/picture/layers/line/list',
        method: 'get',
        params
    })
    return LineListCov
}

export function PersonListCov(params){
    const PersonListCov = axios({
        url: 'patrol/picture/layers/person/list',
        method: 'get',
        params
    })
    return PersonListCov
}
//zypatrol/picture/event/list

export function EventListCov(params){
    const EventListCov = axios({
        url: 'patrol/picture/event/list',
        method: 'get',
        params
    })
    return EventListCov
}

export function lineList(params){
    const linelist = axios({
        url:'zypatrol/facility/equipment/getLineList',
        method: 'get',
        params
    })
    return linelist
}
//zypatrol/network/delete

