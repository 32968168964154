<template>
  <div >    
   
          <div class="formBox" v-if="showForm">
              <span class="t">{{addForm.id?'编辑':'新增'}}设备</span>
              <el-divider></el-divider>
                  <el-form ref="addForm" class="searchForm" :rules="rules" :model="addForm" label-width="80px">
                        <el-form-item label="设备名称" prop="equipmentName" size="mini">
                          <el-input v-model="addForm.equipmentName"  placeholder="请输入设备名称"></el-input>
                        </el-form-item>
                        <el-form-item label="设备类型" prop="eventTypeIdList" size="mini">
                            <!-- <el-select v-model="addForm.equipmentType" placeholder="请选择" style="whith:100%">
                            <el-option
                              v-for="(item, index) in equipmentTypeList"
                              :key="index"
                              :label="item.name"
                              :value='item.id'
                            ></el-option>
                          </el-select> -->
                          <el-cascader
                            size="mini"
                            style="whith:100%"
                            :options="equipmentTypeList"
                            v-model="addForm.eventTypeIdList"
                            :props="{  label:'name' , value:'id' }"
                            separator='>'
                            clearable
                            collapse-tags
                            @change="deformation">
                          </el-cascader>
                        </el-form-item>
                        <el-form-item label="规格型号"  size="mini">
                          <el-input v-model="addForm.equipmentSpecifications" placeholder="请输入规格型号"></el-input>
                        </el-form-item>
                        <el-form-item label="设备状态"  size="mini">
                          <el-select v-model="addForm.equipmentStatus" placeholder="请选择" style="whith:100%">
                            <el-option
                              v-for="item in equipmentStatusList"
                              :key="item.id"
                              :label="item.name"
                              :value="item.dictCode"
                            ></el-option>
                          </el-select>
                        </el-form-item>     
                        <el-form-item label="生产日期"  size="mini">
                          <el-date-picker
                            v-model="addForm.equipmentTime"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            type="datetime"
                            placeholder="选择日期">
                          </el-date-picker>
                        </el-form-item>   
                        <el-form-item label="使用日期" prop="equipmentUseTime" size="mini">
                           <el-date-picker
                            v-model="addForm.equipmentUseTime"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            type="datetime"
                            placeholder="选择日期">
                          </el-date-picker>
                        </el-form-item>   
                        <!-- <el-form-item label="所属部门" prop="equipmentDepartmentId" size="mini">
                          <el-cascader
                              placeholder="请选择所属部门"
                              v-model="addForm.equipmentDepartmentId"
                              :options="equipmentoptions"
                              :props="{ expandTrigger: 'hover',value:'id',label:'name' }"
                          ></el-cascader>
                        </el-form-item>   -->
                        <el-form-item label="所在街道" prop="equipmentStreet" size="mini">
                          <el-input v-model="addForm.equipmentStreet" placeholder="请输入所在街道"></el-input>
                        </el-form-item>
                        <el-form-item label="生产厂家" prop="equipmentProducers" size="mini">
                          <el-input v-model="addForm.equipmentProducers" placeholder="请输入生产厂家"></el-input>
                        </el-form-item>
                        <el-form-item label="所属图层" prop="value" size="mini">
                          <!-- <el-select v-model="addForm.coverage" placeholder="请选择" style="whith:100%">
                            <el-option
                                v-for="(item) in buryMode"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value"
                            ></el-option>
                          </el-select> -->
                          <el-cascader
                            v-model="addForm.value"
                            :options="buryMode"
                            :props='{children: "children",
                              label: "name",
                              emitPath:false ,
                              value:"id"}'
                            :show-all-levels="false"
                            @change="handleChange"></el-cascader>
                        </el-form-item>
                    <!-- <el-form-item label="关联管线" prop="lineId" size="mini">
                      <el-select filterable  clearable v-model="addForm.lineId" placeholder="请选择" style="whith:100%">
                        <el-option
                            v-for="(item, index) in allline"
                            :key="index"
                            :label="item.lineName"
                            :value="item.id"
                        ></el-option>
                      </el-select>
                    </el-form-item> -->
                    <el-form-item label="关联管线" prop="lineId" size="mini" v-if="addForm.lineId">
                      {{addForm.lineName}}
                    </el-form-item>
                    <el-form-item label="所在地址" prop="equipmentHome" size="mini">
                          {{addForm.equipmentHome}}                 
                        </el-form-item>      
                        <el-form-item label-width="0px" class="searchBt">
                          <el-button
                            size="mini"
                            type="primary"
                            class="searchBtn"
                            @click="save"
                            >保存</el-button
                          >
                          <el-button
                            size="mini"
                            class="searchBtn"
                            @click="closePDraw"
                            >取消</el-button
                          >
                        </el-form-item>
                  </el-form>
          </div>

  </div>
</template>

<script>
import {lineList} from '@/RequestPort/gis/index'
import {saveEquipment,selectDictListByParentCode } from '@/apis/commonType'
import { queryFeatrue } from '@/apis/geo'
import {findTreeMap } from "@/RequestPort/Inspection/inspePlan";
import {selectSysDeptTreeList} from "../../../RequestPort/user/user";
import {serverMap} from "@/views/Gis/utils/dict"
import { dicByType } from "@/RequestPort/maintenance";
export default {
  name: 'formEdit',
  props:{
    showForm:{
      type:Boolean,
      default:false
    },     
    map:{
      type:Object,
      default:null
    },    
    addForm:{
      type:Object,
      default: function(){
        return {}
        }
    },
    polyline1:{
      type:Object,
      default: null
    }
  },
  data() {
    return {
      allline:[],
      //  所选图层
      buryMode:[],
      name: '片区',
     equipmentStatusList:[],
     buttons:true,
      equipmentTypeList:[],
      // equipmentoptions:[],
      rules: {
        equipmentName: [
          { required: true, message: '请输入设备名称', trigger: 'blur' },
          { min: 0, max: 100, message: '长度在 0 到 100 个字符', trigger: 'blur' }
        ],
        equipmentSpecifications: [
          { required: true, message: '请输入规格型号', trigger: 'blur' },
          { min: 0, max: 100, message: '长度在 0 到 100 个字符', trigger: 'blur' }
        ],
        equipmentTime: [
          { required: true, message: '请选择生产日期', trigger: 'change' }
        ],
        eventTypeIdList: [
          { required: true, message: '请选择设备类型', trigger: 'change' }
        ],
        equipmentStatus: [
          { required: true, message: '请选择设备状态', trigger: 'change' }
        ],
        equipmentDepartmentId:[
          { required: true, message: '请选择部门', trigger: 'change' }
        ],
        value:[
          { required: true, message: '请选择所属图层', trigger: 'change' }
        ],
      },
    }
  },
  computed: {},
  destroyed() {
    this.$eventBus.$off("wiredata");
    this.$eventBus.$off('setLength') 
  },
  mounted() {
    // this.addForm = {}
    this.equipmentStatusList = serverMap[1007]
    // 设备吸附管线拿到管线信息
    this.$eventBus.$on("wiredata", (data) => {
      if(data){
      this.$set(this.addForm,'lineName',data.lineName)
      this.$set(this.addForm,'lineId',data.lineId)
      this.$set(this.addForm,'lineTable',data.lineTable)
      // this.addForm.lineName = data.lineName
      // this.addForm.lineId = data.lineId
      this.$forceUpdate();
      } else {
        this.addForm.lineName = ''
        this.addForm.lineId = ''
        this.addForm.lineTable = ''
      }
    });
    
      dicByType({parentCode:'sblx',level:3}).then(res => {
        if(res.code == 200) {
            this.equipmentTypeList = res.data
        } else {
            this.$message.error(res.msg)
        }
    })
    /**
       * 获取树1 gis图层  2 巡线图层
       */
      findTreeMap({sysType:2}).then(res => {
        
        let buryModeList = res.data ? res.data : []
        // buryModeList.forEach(item => {
        //   if (item.layerType==1 && !item.children) {
        //     item.disabled = true
        //   }
        // })
        let arrs =  this.circulation(buryModeList)
        this.$set(this,'buryMode',arrs)
        this.$forceUpdate()
      })
      // 有图层的时候
      if(this.addForm.coverage) {
        // this.numbers(this.addForm.coverage)
        this.$set(this.addForm,'value',this.addForm.coverage)
        this.$set(this.addForm,'eventTypeIdList',this.addForm.equipmentType)
      }
    // selectSysDeptTreeList({deptListShow:'y'}).then((e)=>{
    //   this.equipmentoptions = e.data
    // })
    this.$eventBus.$on('setLength', data => { 
      if(data[0] && data[0]._position) {
      this.$set(this.addForm,'locations',data[0]._position)
      } else {
        this.$set(this.addForm,'locations',data._position)
      }
    })
  },
  methods: {
    /**
     * 循环判断是图层组还是图层
     */
    circulation(val){
      val.forEach(item => {
          // 判断为图层组并没有子集
          if (item.layerType==1 && !item.children) {
            item.disabled = true
          } else if(item.children && item.children.length>0){
            // 有子集
            this.circulation(item.children)
          }
        })
        return val
    },
    // 回显图层
    numbers(val){
      
      for(let i=0;i<this.buryMode.length;i++) {
        // 第一层
        if(this.buryMode[i].id == val) {
          return [val]
        } else {
          this.numbers(this.buryMode[i].children)
        }
      }
      [1,2,3]
    },
    /**
     * 事件类型 选择事件
     */
    deformation(){
      if(this.addForm.eventTypeIdList[1]) {
      this.addForm.equipmentType = this.addForm.eventTypeIdList[1]
      } else {
        this.addForm.eventTypeIdList = null
      }
    },
    resetFields(){
      if(this.$refs['addForm']){
        this.$refs['addForm'].resetFields()
      }
     
    },
    handleChange(){
      this.addForm.coverage = this.addForm.value
    },
    save(){
      // if(this.polyline1){
      //     this.addForm.location=this.polyline1.getPath()
      // }
      // 设置开关防止多次点击
      if(!this.buttons) {
        return
      } else {
        
        this.$refs['addForm'].validate(valid => {
            if (valid) {   
              this.buttons = false
              this.getNetwork()
            }
        })
      }
    },
    getNetwork(){
      
      // let file=this.getfile(this.addForm.locations)
      const that = this
      let langlat = new AMap.LngLat(this.addForm.locations[0],this.addForm.locations[1])
      let points = that.centerPointGetFourPoint(langlat)
      let a = points.leftBottomPoint[0]
      let b = points.leftBottomPoint[1]
      let c = points.upRightPoint[0]
      let d = points.upRightPoint[1]
      let parames={
          SERVICE: 'WMS',
          VERSION: '1.1.0',
          REQUEST: 'GetFeatureInfo',
          FORMAT: 'image/png',
          TRANSPARENT: true,
          QUERY_LAYERS: 'linqing:patrol_networks',
          LAYERS: 'linqing:patrol_networks',
          exceptions: 'application,Fvnd.ogc.se_inimage',
          INFO_FORMAT: 'application/json',
          FEATURE_COUNT: 10,
          X: 50,
          y: 50,
          SRS: 'EPSG:4326',
          WIDTH: 101,
          HEIGHT: 101,
          // ...cql,
          BBOX: a + ',' + b + ',' + c + ',' + d,
          propertyName: 'id'
      }
      
      queryFeatrue(parames).then(res => {
        if(res.features&&res.features[0]){
          let ids =[]
          res.features.forEach(el => {
            ids.push(el.properties.id)
          });
         this.addForm.networkId=ids.join(',')
        }         
         this.saveApi()
      })
    },
    centerPointGetFourPoint(lnglat) {
      let margin = 100
      let mapes = this.map.map ? this.map.map : this.map
      let Pixel = mapes.lngLatToContainer(lnglat)
      let newPixel = new AMap.Pixel(Pixel.x - margin, Pixel.y)
      const newLnglat = mapes.containerToLngLat(newPixel)

      let side = AMap.GeometryUtil.distance(lnglat, newLnglat)

      const centerPoint = lnglat
      const upLeftPoint = centerPoint.offset(-parseInt(side / 2), parseInt(side / 1.9))
      const upRightPoint = centerPoint.offset(parseInt(side / 2), parseInt(side / 1.9))
      const leftBottomPoint = centerPoint.offset(-parseInt(side / 2), -parseInt(side / 1.7))
      const rightBottomPoint = centerPoint.offset(parseInt(side / 2), -parseInt(side / 1.7))
      return {
        upLeftPoint: [upLeftPoint.lng, upLeftPoint.lat],
        upRightPoint: [upRightPoint.lng, upRightPoint.lat],
        leftBottomPoint: [leftBottomPoint.lng, leftBottomPoint.lat],
        rightBottomPoint: [rightBottomPoint.lng, rightBottomPoint.lat]
      }
    },
    saveApi(){
         let parames=this.addForm
               parames.lng=this.addForm.locations[0]
               parames.lat=this.addForm.locations[1]
                // parames.equipmentDepartmentId = this.addForm.equipmentDepartmentId[this.addForm.equipmentDepartmentId.length-1]
                saveEquipment(parames).then(res => {
                if(res.code==200){
                  this.$message.success('操作成功')
                  this.$emit('save')
                  this.$eventBus.$emit("removeDot")
                  this.buttons = true
                }                
              })
    },
    getfile(lnglat){
      let file='<Filter xmlns:gml="http://www.opengis.net/gml">'+
                     
                        ' <Intersects>'+
                        ' <PropertyName>location</PropertyName>'+
                      '   <gml:Point>'+
                            '  <gml:coordinates>'+lnglat[0]+','+lnglat[1]+'</gml:coordinates>'+
                        ' </gml:Point>'+
                    '  </Intersects>'+
                '  </Filter>'
      return file
    },
    closePDraw(){
      this.$emit('closePDraw')
    }
   
  }
}
</script>

<style lang="less" scoped>

.searchForm{margin:15px 15px 0 5px;}
.searchBt{text-align: center;}
.formBox{position:absolute;left:10%;top:100px;background: #fff;width: 300px;padding-top:10px;z-index: 3;
.t{padding-left:10px;font-size: 16px;}
::v-deep{.el-divider--horizontal{margin-top:10px;margin-bottom:10px}
.el-date-editor.el-input, .el-date-editor.el-input__inner{width: 100%;}
}
}
</style>
